.PhoneInput .PhoneInputInput {
  width: 100%;
  border: 1px solid #00000021;
  outline: none;
  background-color: #00000010;
  font-size: 1rem;
  border-radius: 0.14rem;
  padding: 0.5rem 0.6rem;
  display: block;
  font-family: BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.PhoneInput .PhoneInputCountry {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
