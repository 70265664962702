.answer-form {
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.answer-form,
.answer-form * {
  box-sizing: border-box;
}

.answer-form .content {
  width: 100%;
  max-width: 800px;
  padding-top: 2rem;
}

.answer-form h1 {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #383838;
}

.answer-form h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #383838;
}

.answer-form .card {
  background-color: white;
  border-radius: 0.3rem;
  padding: 1rem;
}

.answer-form .m-0 {
  margin: 0;
}

.answer-form .mb-3 {
  margin-bottom: 1rem;
}

.answer-form input.form-control,
.answer-form textarea.form-control {
  width: 100%;
  border: 1px solid #00000021;
  outline: none;
  background-color: #00000010;
  font-size: 1rem;
  border-radius: 0.14rem;
  padding: 0.5rem 0.6rem;
  display: block;
  font-family: BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.answer-form textarea.form-control {
  resize: vertical;
}

.answer-form .btn {
  border: 0;
  background-color: #2a6ecf;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
}

.answer-form .btn:hover,
.answer-form .btn:focus,
.answer-form .btn:active {
  background-color: #235daf;
}

.answer-form .text-center {
  text-align: center;
}

.answer-form .field-error-message {
  color: #e74c3c;
}
